import {mapGetters, mapMutations} from "vuex";

export default {
  props:['item'],
  name: "basket-item",
  data(){
    return{
      currencyIco:''
    }
  },
  created() {
  this.checkCurrency()
  },
  computed:{
    ...mapGetters({
      currency:'setting/currentCurrency',
    })
  },
  methods:{
    ...mapMutations({
      increment: 'basket/INCREMENT_BASKET_LIST_COUNT',
      decrement: 'basket/DECREMENT_BASKET_LIST_COUNT',
      setCount: 'basket/SET_BASKET_LIST_COUNT',
      remove: 'basket/REMOVE_ITEM_FROM_BASKET',

    }),
    checkCurrency(){
      if (localStorage.getItem('currency') === 'USD')
        this.currencyIco = '$'
      else if(localStorage.getItem('currency') === 'JPY')
        this.currencyIco = '¥'
      else
        this.currencyIco = '₽'
    },
    changeCount(type, count = 1) {
      if (type === 'increment') {
        this.increment(this.item)
      } else if (type === 'decrement') {
        this.decrement(this.item)
      } else {
        if (count) {
          this.setCount({
            ...this.item,
            select_count: parseInt(count)
          })
        }
      }
    }
  }

}