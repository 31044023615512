import basketItem from '../../components/basket-item/index.vue'
import basketInfo from '../../components/basket-info/index.vue'
import {mapActions,mapMutations,mapGetters} from "vuex";
export default {
  name: "basket",
  components:{
    basketItem,
    basketInfo
  },
  created() {
    if(this.basket && this.basket.length){
      this.getProductsIds()
    } 
  },
  computed:{
    ...mapGetters({
      showBasket: 'basket/showBasket',
      basket: 'basket/basket',
      count: 'basket/count',
      countBasketItems: 'basket/countBasketItems',
    }),
  },
  methods: {
    ...mapActions({
      fetchBasketProductsCurrencies: `basket/SEND_BASKET_PRODUCTS_IDS`
    }),
    getProductsIds() {
      const productsIDs = this.basket.map((item) => item.id)
      this.fetchBasketProductsCurrencies({products: productsIDs})
    }
  }
}