import {mapActions,mapMutations,mapGetters} from "vuex";
export default {
  name: "basket-info",
  data(){
    return{
      currencyIco:''
    }
  },
  computed:{
    ...mapGetters({
      basket: 'basket/basket',
    }),
    totalAmount() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.price * currentValue.select_count);
      }, 0);
    },
    totalWeight(){
      return this.basket.reduce((accumulator, currentWeight) => {
        return accumulator + (currentWeight.dimensions.weight * currentWeight.select_count);
      }, 0);
    }
  },
  created() {
    this.checkCurrency()
  },
  methods:{
    checkCurrency(){
      if (localStorage.getItem('currency') === 'USD')
        this.currencyIco = '$'
      else if(localStorage.getItem('currency') === 'JPY')
        this.currencyIco = '¥'
      else
        this.currencyIco = '₽'
    },
    submit(){
      this.$router.push({name: 'confirm-order'}).catch(() => {console.log()});
    }
  }
}